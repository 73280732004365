import React from 'react'
import NavBar from '../../components/common/NavBar'
import TeamHeadline from '../../components/about/team/TeamHeadline'
import Divider from '../../components/common/Divider'
import TeamContainer from '../../components/about/team/TeamContainer'
import Helmet from 'react-helmet'
import Layout from '../../layouts';
import { graphql } from "gatsby"

const Team = ({ data, location }) => {
  const { phone, email } = data.contact.contact
  const teamMemberNodes = data.allEmployeesJson.edges
  const { newEmployeeImage } = data;
  
  return (
    <Layout>
      <Helmet
        title={'Team | About | Zaven'}
        meta={[
          {
            name: 'description',
            content:
              'Meet Zaven’s team and read more about our software development company located in Poland. Now you can hire the best React, .NET and NodeJS developers!',
          },
          {
            name: 'keywords',
            content:
              'full stack developers, web development Poland, developers outsourcing, top mobile developers Poland',
          },
        ]}
      />
      <NavBar phoneNumber={phone} email={email} lightTheme location={location}/>
      <TeamHeadline />
      <Divider rainbow fullWidth />
      <TeamContainer teamMemberNodes={teamMemberNodes} newEmployeeImage={newEmployeeImage}/>
    </Layout>
  )
}

export default Team

export const teamQuery = graphql`
  query TeamPageQuery {
    contact: dataJson {
      ...Contact
    }
    allEmployeesJson {
      ...Employees
    }
    newEmployeeImage: imageSharp(fluid: { originalName: { regex: "/zaven_www_nowy_pracownik/" } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
